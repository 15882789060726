
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































































































.contact-form {
  border-top: 1px solid $c-gray-light;
}

.contact-form__title {
  @extend %text-center;

  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4.5;
  }
}

.form {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4;
  }
}

.form-inner {
  @include mq(m) {
    padding: 0 col(2, 12);
  }

  @include mq(xl) {
    padding: 0 col(3, 12);
  }
}

.form__fieldset {
  margin-left: 0;
  padding: 0;
  border: 0;

  .select,
  .textarea {
    @include mq('s') {
      grid-column: 1 / 3;
    }
  }
}

.form__fieldset,
.form__confirmation {
  @include fluid(
    column-gap,
    (
      xxs: 10px,
      xxl: 35px,
    )
  );
  @include fluid(
    row-gap,
    (
      xxs: 20px,
      xxl: 25px,
    )
  );

  margin-top: 0;

  @include mq('s') {
    display: grid;
    grid-template: auto / 1fr 1fr;
  }
}

.form__input {
  margin-top: $spacing;

  &.full {
    grid-column: 1 / 3;
  }
}

.form__confirmation {
  margin-left: 0;
  padding: 0;
  border: 0;

  ::v-deep .checkbox .form-feedback {
    position: static;
  }

  @include mq('s') {
    display: block;
  }

  @include mq('xl') {
    grid-template: auto / 1fr 300px;
    row-gap: 0;
  }
}

.form__confirmation__recaptcha {
  @include mq('xl') {
    grid-row: 2 / 2;
    grid-column: 1 / 2;
    margin-top: $spacing;
  }
}

.form__send {
  position: relative;
  padding: $spacing * 2 0;
  text-align: center;
  border: 0;

  /* stylelint-disable-next-line no-descending-specificity */
  .form-feedback {
    position: absolute;
    top: 0;
    text-align: center;
  }

  @include mq(m) {
    margin-top: $spacing * 3;
    text-align: right;

    .form-feedback {
      @include center-y;

      text-align: left;
    }
  }
}

.form-label__required {
  color: $c-mustard;
}

.form__feedback {
  width: 100%;
}
